import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'

const policyLayout = {
  personalData: {
    list1: [{ items: 4 }, { items: 2 }, { items: 5 }],
    list2: { items: 9 },
  },
}

const tempMessages = {
  en: 'Coming soon',
  de: 'Momentan nicht erhältlich',
}

const PrivacyPolicyPage = () => {
  const { formatMessage, locale } = useIntl()

  const renderSectionParagraphs = (section, count = 1, start = 1) => {
    const elements = []
    const end = start + (count - 1)
    for (let i = start; i <= end; i++) {
      elements.push(
        <p
          key={i}
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: formatMessage({
              id: `pages.privacyPolicy.sections.${section}.p${i}`,
            }),
          }}
        />
      )
    }
    return elements
  }

  const renderSectionFlatListItems = (section, listNumber, count) => {
    const listItems = []
    for (let i = 1; i <= count; i++) {
      listItems.push(
        <li
          key={i}
          className="pl-2"
          dangerouslySetInnerHTML={{
            __html: formatMessage({
              id: `pages.privacyPolicy.sections.${section}.l${listNumber}.li${i}`,
            }),
          }}
        />
      )
    }
    return listItems
  }

  const renderPersonalDataListOne = () => {
    const renderInnerList = (langKey, count) => {
      const listItems = []
      for (let i = 1; i <= count; i++) {
        listItems.push(
          <li key={i} className="pl-2">
            {formatMessage({ id: `${langKey}.l1.li${i}` })}
          </li>
        )
      }
      return listItems
    }

    return policyLayout.personalData.list1.map((listItem, index) => {
      const langKeyIndex = `pages.privacyPolicy.sections.personalData.l1.li${index +
        1}`
      return (
        <li key={index} className="mt-4 pl-2">
          {formatMessage({ id: `${langKeyIndex}.text` })}
          <ol className="list-alphalower pl-4 mt-2">
            {renderInnerList(langKeyIndex, listItem.items)}
          </ol>
        </li>
      )
    })
  }

  const isLocaleSpanish = locale === 'es'

  return (
    <Layout headerLinkColor="lg:text-purple">
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.privacyPolicy.title' })}
        description={formatMessage({ id: 'pages.privacyPolicy.description' })}
      />

      <div className="pt-48 pb-24 bg-white text-gray">
        <div className="custom-container custom-container--large text-justify">
          <h1 className="heading text-center">
            {formatMessage({ id: 'pages.privacyPolicy.title' })}
          </h1>

          {isLocaleSpanish ? (
            <>
              {/* Introduction */}
              <section className="mt-12">
                {renderSectionParagraphs('introduction', 9)}
              </section>

              {/* Personal data */}
              <section className="mt-12">
                <h2 className="heading text-center text-2xl">
                  {formatMessage({
                    id: 'pages.privacyPolicy.sections.personalData.title',
                  })}
                </h2>

                {renderSectionParagraphs('personalData', 2)}

                <ol className="list-romanupper mt-4 pl-4">
                  {renderPersonalDataListOne()}
                </ol>

                {renderSectionParagraphs('personalData', 4, 3)}

                <ol className="list-decimal mt-4 pl-4">
                  {renderSectionFlatListItems('personalData', 2, 9)}
                </ol>
              </section>

              {/* Data collection */}
              <section className="mt-12">
                <h2 className="heading text-center text-2xl">
                  {formatMessage({
                    id: 'pages.privacyPolicy.sections.dataCollection.title',
                  })}
                </h2>

                {renderSectionParagraphs('dataCollection', 4)}

                <ul className="list-disc mt-4 pl-4">
                  {renderSectionFlatListItems('dataCollection', 1, 2)}
                </ul>

                {renderSectionParagraphs('dataCollection', 1, 5)}

                <ul className="list-disc mt-4 pl-4">
                  {renderSectionFlatListItems('dataCollection', 2, 3)}
                </ul>

                {renderSectionParagraphs('dataCollection', 2, 6)}
              </section>

              {/* Cookies */}
              <section className="mt-12">
                <h2 className="heading text-center text-2xl">
                  {formatMessage({
                    id: 'pages.privacyPolicy.sections.cookiesPolicy.title',
                  })}
                </h2>

                {renderSectionParagraphs('cookiesPolicy', 2)}
              </section>

              {/* Social media */}
              <section className="mt-12">
                <h2 className="heading text-center text-2xl">
                  {formatMessage({
                    id: 'pages.privacyPolicy.sections.socialMedia.title',
                  })}
                </h2>

                {renderSectionParagraphs('socialMedia', 3)}
              </section>

              {/* Consent */}
              <section className="mt-12">
                <h2 className="heading text-center text-2xl">
                  {formatMessage({
                    id: 'pages.privacyPolicy.sections.consentRevocation.title',
                  })}
                </h2>

                {renderSectionParagraphs('consentRevocation', 1)}

                <ul className="list-disc mt-4 pl-4">
                  {renderSectionFlatListItems('consentRevocation', 1, 4)}
                </ul>

                {renderSectionParagraphs('consentRevocation', 2, 2)}
              </section>

              {/* Contact */}
              <section className="mt-12">
                <h2 className="heading text-center text-2xl">
                  {formatMessage({
                    id: 'pages.privacyPolicy.sections.contact.title',
                  })}
                </h2>

                {renderSectionParagraphs('contact', 1)}
              </section>
            </>
          ) : (
            <p className="mt-8 text-center">{tempMessages[locale]}</p>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
